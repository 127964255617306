import { graphql } from 'gatsby';
import { arrayOf, string } from 'prop-types';
import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import { pictureFluidType } from '../../../types/picture';
import { Content } from '../../common/content';
import { FluidPicture } from '../../common/picture';
import VideoContext from '../../../hooks/useVideo';
import { usePrivacyConsent } from '../../../hooks/usePrivacy';
import './youtube.scss';

const bem = new BEMHelper({
  name: 'content-youtube',
});

const IS_PLAYING = 1;

const PLAYER_OPTIONS = {
  playerVars: {
    autoplay: 1,
  },
};

export const Youtube = ({
  id,
  videoId,
  description,
  image: [image],
}) => {
  const { t } = useTranslation();
  const canUseContents = usePrivacyConsent('contents');
  const [showVideo, setShowVideo] = useState(false);
  const { videos, setVideos } = useContext(VideoContext);

  useEffect(() => setVideos([]), []);

  const handleReady = useCallback(({ target }) => setVideos(videos.concat(target)));

  const onPlayerStateChange = useCallback(({ data }) => {
    if (data === IS_PLAYING) {
      videos.forEach((video) => {
        if (video.playerInfo.videoData.video_id !== videoId) {
          video.stopVideo();
        }
      });
    }
  });

  const onClickPicture = useCallback(() => setShowVideo(true));

  return (
    <Content id={id} {...bem()}>
      {description && (<p {...bem('description')}>{description}</p>)}
      <div {...bem('wrapper')}>
        {!canUseContents ? (
          <a
            href={`https://www.youtube.com/watch?v=${videoId}`}
            rel="noopener"
            target="_blank"
            aria-label={t('Open video in new tab')}
            title={t('Click here to open the video in a new tab')}
            {...bem('button')}
          >
            <FluidPicture
              {...image}
              maxWidth={895}
              widths={[224, 448, 895, 1343, 1790]}
            />
          </a>
        )
          : !showVideo
        && (
        <button
          type="button"
          aria-label={t('Play video')}
          title={t('Click here to play the video')}
          onClick={onClickPicture}
          {...bem('button')}
        >
          <FluidPicture
            {...image}
            maxWidth={895}
            widths={[224, 448, 895, 1343, 1790]}
          />
        </button>
        )}
        {showVideo
        && (
          <YouTube
            opts={PLAYER_OPTIONS}
            videoId={videoId}
            className={bem('video').className}
            containerClassName={bem('container').className}
            onReady={handleReady}
            onStateChange={onPlayerStateChange}
          />
        )}
      </div>
    </Content>
  );
};

Youtube.propTypes = {
  id: string.isRequired,
  videoId: string.isRequired,
  image: arrayOf(pictureFluidType).isRequired,
  description: string,
};

Youtube.defaultProps = {
  description: undefined,
};

export const fragment = graphql`
  fragment YoutubeContentFragment on CraftGQL_pageContents_youtube_BlockType {
    id
    videoId
    description
    image {
      ...Picture
      fluid_0: url(width: 224, mode: "crop", position: "") # 0.25
      fluid_1: url(width: 448, mode: "crop", position: "") # 0.5
      fluid_2: url(width: 895, mode: "crop", position: "") # max width
      fluid_3: url(width: 1343, mode: "crop", position: "") # 1.5
      fluid_4: url(width: 1790, mode: "crop", position: "") # 2
    }
  }
`;
